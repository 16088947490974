<template>
  <div class="content">
    <div class="banner">
      <img  src="../assets/banner.jpg" />
    </div>
    <div class="itembox">
      <div class="item-title">
        产品中心
      </div>
      <div class="item-subtitle">
        - 数据改变农业、智慧创造未来 -
      </div>
      <div class="item-content">
         <div class="one">
            <img class="pro" src="../assets/01.jpg" />
            <div class="one-title">
              智慧农业大数据平台
            </div>
         </div>
         <div class="one">
            <img class="pro" src="../assets/02.png" />
            <div class="one-title">
              智能物联设备
            </div>
         </div>
         <div class="one">
            <img class="pro" src="../assets/03.jpg" />
            <div class="one-title">
              农产品二维码溯源系统
            </div>
         </div>
         <div class="one">
            <img class="pro" src="../assets/04.jpg" />
            <div class="one-title">
              农产品检测系统
            </div>
         </div>
      </div>
    </div>
    <div class="itembox">
      <div class="item-title">
        公司简介
      </div>
      <div class="item-subtitle">
        - 智慧农业「一站式」服务商 - 
      </div>
      <div class="item-content1">
         山东亿腾农业科技发展有限公司是一家专业从事智慧农业大数据平台建设、农产品溯源体系建设、智慧农业环境数据监测、物联网感知设备研发、水肥一体化、
         智慧农业园区规划及运营的高科技公司。公司拥有自己的核心研发团队和多项软件及硬件信息化技术产品，通过多年积累的技术和项目经验，
         已经形成了集研发、生产、销售、服务于一体的运营管理体系，为客户提供优质的产品和解决方案。 
         
      </div>
      <div class="item-content1">
        公司涵盖的业务范围包括：农业信息化平台建设(包括智慧农业大数据平台、农产品二维码溯源系统、
         农产品检测系统、设施农业控制系统、智能水肥一体化控制系统等)；农业传感数据采集设备、物联网控制设备、水肥一体化设备的生产；设施农业精准补光系统建设；
         农产品销售；智慧农业园区规划及运营等。 山东峰歌信息科技有限公司将始终坚持“诚信为本、合作共赢”的发展理念，以现代农业多元化发展为方向，
         不断进行技术创新，愿与各方合作，为中国智慧农业的发展贡献自己的一份力量。
      </div>
    </div>
    <div class="bottom">
      <div class="bottombox">
        <div class="two">
          <div class="name">山东亿腾农业科技发展有限公司</div>
          <div class="address">
             地址：山东省潍坊市寿光市圣城街道渤海路金顿大厦
          </div>
          <div class="beian">
            <span>备案号码：</span>
            <a href="http://beian.miit.gov.cn/" target="_blank">鲁ICP备2021044569号-1</a>
          </div>
        </div>
        <div class="two">
          <div class="name">联系我们</div>
          <div class="address">
             联系人：吕经理
          </div>
          <div class="address">
             联系电话：15866518610
          </div>
        </div>
      </div>
      
       
       
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
<style scoped>
.contet{
  width: 100%;
  height: 100%;
}
.itembox{
  width: 1200px;
  margin: 20px auto;
}
.bottom{
  width: 1200px;
  margin: 20px auto;
  height: 160px;
  border-top: 1px solid #e8e8e8;
}
.bottombox{
  width: 100%;
  margin-top: 20px;
  display: inline-block;
}
.two{
  float:left;
  width: 600px;
}
.name{
  text-align: left;
  font-size: 20px;
  color:#000;
  margin: 10px 0;
}
.address{
  font-size: 16px;
  line-height: 32px;
  color: #2c3e50;
}
.beian{
  font-size: 16px;
  line-height: 32px;
}
.beian a{
  color: #2c3e50;
  list-style: none;
  text-decoration: none;
  background-color: transparent;
}
.item-title{
  text-align: center;
  font-size: 40px;
  color:#28a745;
  margin: 10px;
}
.item-subtitle{
  text-align: center;
  font-size: 20px;
}
.item-content{
  width: 100%;
  margin-top: 20px;
  display: inline-block;
}
.item-content1{
  width: 100%;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  margin-top: 20px;
  display: inline-block;
}
.one{
  float:left;
  width: 280px;
  margin: 0 5px;
  height: 320px;
  border: 1px solid #ccc;
}
.pro{
  /* width: 500px; */
  width: 100%;
  height: 250px;
}
.one-title{
  text-align:center;
  font-weight: 600;
  font-size: 24px;
  line-height: 70px;
}
</style>
