<template>
  <div id="app">
    <div class="logobox">
      <img class="logo" src="./assets/logo.jpg" />
    </div>
    
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
  
  width: 1920px;
  margin: 0 auto;
  
}
.logobox{
width: 1200px;
margin: 0 auto;
}
.logo{
  
margin-bottom: 10px;
margin-left: 20px;
}
</style>
